// This file is auto generated from scripts/build/gen_files_by_env.cjs
// Current DEV_ENV=qa

import * as modclub from "../../declarations/modclub_qa";
import * as modclub_assets from "../../declarations/modclub_qa_assets";
import * as rs from "../../declarations/rs_qa";
import * as vesting from "../../declarations/vesting_qa";
import * as wallet from "../../declarations/wallet_qa";
import * as airdrop from "../../declarations/airdrop_qa";

export { modclub, modclub_assets, rs, vesting, wallet, airdrop };
